import React from 'react';
import "animate.css/animate.compat.css"
import { createRoot } from 'react-dom/client'; // Updated import for React 18
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { load, save } from 'redux-localstorage-simple';
import thunk from 'redux-thunk';
import App from './App';
import rootReducer from './redux/reducers/rootReducer';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';

// import { onAuthStateChanged } from 'firebase/auth';
// import { auth } from './firebase';

const store = createStore(
  rootReducer,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save())),
);

// onAuthStateChanged(auth, user => {
//   if (user) {
//     console.log('User is signed in:', user);
//     const idTokenResult = user.getIdTokenResult();
//   } else {
//     console.log('No user is signed in');
//   }
// });

const container = document.getElementById('root');
const root = createRoot(container); // Create root for React 18

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
