import axios from 'axios';
import Cookies from 'js-cookie'; // Ensure you have the js-cookie library installed
import { auth } from '../firebase';
import { transformPayloadToSend } from './transformPayload';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const getAuthToken = async () => {
  const localToken = sessionStorage.getItem('AuthToken');
  if (localToken) {
    return localToken;
  }

  return new Promise((resolve, reject) => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const token = await user.getIdToken(true); // Force refresh the token
          sessionStorage.setItem('AuthToken', token); // Store the token in session storage
          resolve(token);
        } catch (error) {
          console.error('Error refreshing Firebase token:', error);
          reject(error);
        }
      } else {
        resolve(null);
      }
    }, reject);
  });
};

// Add request interceptor to include the token and CSRF header in requests
axiosInstance.interceptors.request.use(
  async function (config) {
    try {
      // Add Firebase token if available
      const token = await getAuthToken();
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      debugger;
      // Add CSRF token to headers
      const csrfToken = Cookies.get('csrftoken'); // Use js-cookie to retrieve the CSRF token
      if (csrfToken) {
        config.headers['X-CSRFToken'] = csrfToken;
      }

      // Transform the request payload if needed
      if (config.data) {
        config.data = transformPayloadToSend(config.data);
      }
      return config;
    } catch (error) {
      console.error('Error in Axios request interceptor:', error);
      return Promise.reject(error);
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add response interceptor to handle 403 errors
axiosInstance.interceptors.response.use(
  (response) => response, // Pass successful responses as-is
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true; // Prevent infinite retry loop

      try {
        const newToken = await getAuthToken(); // Refresh the token
        if (newToken) {
          sessionStorage.setItem('AuthToken', newToken);
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
          return axiosInstance(originalRequest); // Retry the request with the new token
        }
      } catch (refreshError) {
        console.error('Error refreshing token after 403:', refreshError);
      }
    }

    return Promise.reject(error); // Reject the error if not recoverable
  }
);

export default axiosInstance;
