import {
    AUTH_SUCCESS,
    AUTH_FAIL,
    AUTH_LOGOUT,
    CREATE_AUTH_USER_SUCCESS,
    CREATE_AUTH_USER_FAIL
} from "../actions/firebaseAuthTypes";

const initState = {
    userCredential: {},
    user: {},
    idTokenResult: {},
    adminDeveloper: false,
    premiumUser: false,
    trialUser: false,
    trialStartAt: null,
    trialEndAt: null,
    isTrialEnded: false,
};

const resetState = () => ({
    userCredential: {},
    user: {},
    idTokenResult: {},
    adminDeveloper: false,
    premiumUser: false,
    trialUser: false,
    trialStartAt: null,
    trialEndAt: null,
    isTrialEnded: false,
});
const firebaseAuthReducer = (state = initState, action) => {
    switch (action.type) {
        case AUTH_SUCCESS:
            return {
                ...state,
                userCredential: action.userCredential,
                user: action.user,
                idTokenResult: action.idTokenResult,
                adminDeveloper: action.idTokenResult.claims?.['admin_developer'] === true,
                premiumUser: action.idTokenResult.claims?.['stripeRole'] === 'premium',
                trialUser: action.idTokenResult.claims?.['stripeRole'] === 'trial',
                trialStartAt: action.idTokenResult.claims?.['trial_start_at'],
                trialEndAt: action.idTokenResult.claims?.['trial_end_at'],
                isTrialEnded: action.idTokenResult.claims?.['stripeRole'] === 'trial_ended',
            };
        case CREATE_AUTH_USER_SUCCESS:
            return {
                ...state,
                userCredential: action.userCredential,
                user: action.user,
                idTokenResult: action.idTokenResult,
                adminDeveloper: action.idTokenResult.claims?.['admin_developer'] === true,
                premiumUser: action.idTokenResult.claims?.['stripeRole'] === 'premium',
                trialUser: action.idTokenResult.claims?.['stripeRole'] === 'trial',
                trialStartAt: action.idTokenResult.claims?.['trial_start_at'],
                trialEndAt: action.idTokenResult.claims?.['trial_end_at'],
                isTrialEnded: action.idTokenResult.claims?.['stripeRole'] === 'trial_ended',
            };
        case AUTH_FAIL:
        case AUTH_LOGOUT:
        case CREATE_AUTH_USER_FAIL:
            return {
                ...state,
                ...resetState(),
            };
        default:
            return state;
    }
};

export default firebaseAuthReducer;

export const getAuthUser = (state) => state.firebaseAuth;